<template>
  <div>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <Dialog
        :dialog="dialog"
        :title="'Neue Notiz anlegen'"
        :values="entryCreateFields"
        :successButton="'Erstellen'"
        :icon="'mdi-ring'"
        :cut="3"
        @hideDialog="hideDialog"
        @emitNewData="createEntry"
    ></Dialog>
    <CreateCustomer
        :dialog="dialogCustomer"
        @hideDialog="hideDialogCustomer"
    >
    </CreateCustomer>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import {date2String, error, msgObj, success} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const Dialog = () => import("@/components/dialog/FormDialogNew");
const CreateCustomer = () => import("@/components/dialog/createDialogs/CreateCustomer");

export default {
  name: "CreateWedding",
  components: {
    Dialog,
    Message,
    CreateCustomer
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('settings', {
      weddingStatus: 'weddingStatusForSelection',
    }),
    ...mapGetters('wedding', {
      weddings: 'weddingsForSelection',
    }),
  },
  mounted() {
    this.$store.dispatch('settings/getStatusForSelection', {
      uid: this.user.id,
    }).then(()=>{
      this.$store.dispatch('wedding/getWeddingsForSelection', {
        uid: this.user.id
      }).then(()=>{
        this.entryCreateFields = this.getEntryCreateFields()
      })
    })
  },
  data() {
    return {
      createFields: [],
      dialogCustomer: false,
      message: msgObj(),
      entryCreateFields: []
    }
  },
  props: {
    dialog: Boolean,
    weddingSelection: String,
    openAfterCreate: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  methods: {
    hideDialog() {
      this.$emit('hideDialog')
      this.entryCreateFields = this.getEntryCreateFields()
    },
    hideDialogCustomer() {
      this.dialogCustomer = false
    },
    createEntry(newValues) {
      let newEntry = this.getEntryFromInput(newValues)
      this.$store.dispatch('wedding/createHistoryEntry', {
        historyEntry: newEntry,
        uid: this.user.id,
      }).then(() => {
            this.hideDialog()

            this.message = success('Eintrag wurde erfolgreich angelegt.')
            this.$emit('createdEntry')
          }
      ).catch((err) => {
        this.message = error(err)
      })
    },
    getStringDate(date) {
      return date2String(date)
    },
    getEntryCreateFields() {
      return [
        {
          title: 'Titel*',
          id: 0,
          type: 'string',
        },
        {
          title: 'Text',
          value: '',
          id: 1,
          type: 'string',
        },
        {
          title: 'Typ',
          value: 'Notiz',
          id: 2,
          items: ['Notiz','Anruf', 'Nachricht' ],
          type: 'autoComplete',
        },
        {
          title: 'Datum',
          value: new Date().toISOString().substr(0,10),
          id: 3,
          type: 'date',
        },
        {
          title: 'Zeit',
          value: getCurrentTime(),
          id: 4,
          type: 'time',
        },


      ]
    },
    getEntryFromInput(newValues) {
      let newWedding = {
        weddingId: this.weddingSelection,
        title: '',
        type: '',
        text: '',
        date: null,
        time: null,
        plannerId: this.user.id,
      }
      for (let value of newValues) {
        switch (value.title) {
          case 'Titel*':
            newWedding.title = value.value
            break;
          case 'Text':
            newWedding.text = value.value
            break;
          case 'Typ':
            newWedding.type = value.value
            break;
          case 'Datum':
            newWedding.date =new Date(value.value).getTime()
            break;
          case 'Zeit':
            newWedding.time = value.value
            break;
        }
      }
      return newWedding
    },

  },
}
function getCurrentTime(){
  let date = new Date().setTime(new Date().getTime() + (60*60*1000));
  let newDate = new Date(date)
  return newDate.toISOString().substr(11,5)
}
</script>
